import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";

import JokingMedia from "../../Assets/Projects/JokingMedia.png";
import Portfolio from "../../Assets/Projects/laptop.png";
import ATM from "../../Assets/Projects/Atm.png";
import fusion from "../../Assets/Projects/fusion.jpg";
import mentalHealth from "../../Assets/Projects/mentalHealth.png";

import "./project.css";

function Projects() {
    return (<Container fluid className="project-section">
        <Container>
            <h1 className="project-heading">
                My Recent <strong className="Fluorescent-Blue">Projects </strong>
            </h1>
            <p>Here are a few projects I've worked on recently.</p>
            <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                <Col md={6} lg={4} className="project-card">
                    <ProjectCard
                        imgPath={fusion}
                        title="Multi Sensor Fusion for FS-AI"
                        description="As a developer and assistant technical director, I'm contributing to an innovative project in the Formula Student AI competition. Im developing a multi-sensor fusion subsystem using ZED 2 AI Stereo Lab and LiDAR sensors to enhance navigation and obstacle detection for autonomous race cars, driving advancements in autonomous vehicle technology."
                    />
                </Col>

                <Col md={6} lg={4} className="project-card">
                    <ProjectCard
                        imgPath={Portfolio}
                        title="Portfolio Website"
                        description="My personal Portfolio Website built with React and Bootstrap. It is fully responsive website which supports both dark and light mode."
                        ghLink="https://github.com/AdrianT18/Portfolio"
                        demoLink="https://www.adriantopolski.tech/"
                    />
                </Col>
                <Col md={6} lg={4} className="project-card">
                    <ProjectCard
                        imgPath={JokingMedia}
                        title="Joking Media"
                        description="I developed an appealing, static website for a marketing business for one of my first customers, utilising React.js and infusing it with my own unique design style."
                        ghLink="https://github.com/AdrianT18/JokingMedia"
                        demoLink="https://www.jokingmedia.io/"
                    />
                </Col>

                <Col md={6} lg={4} className="project-card">
                    <ProjectCard
                        imgPath={ATM}
                        title="Virtual ATM Machine"
                        description="I embarked on one of my first major projects, developing a simulated ATM machine that incorporated CRUD operations using MySQL. This endeavor not only sharpened my Java skills but also familiarised me with JavaFX, MySQL, JDBC API, and GitHub, significantly contributing to my growth as a programmer."
                        ghLink="https://github.com/AdrianT18/Atm-Machine"
                    />
                </Col>

                <Col md={6} lg={4} className="project-card">
                    <ProjectCard
                        imgPath={mentalHealth}
                        title="Mental Health App"
                        description="During my second year at university, I led the development of a mental health web application using Agile methodology. This involved implementing key user access features such as Login, Registration, and Password Reset with JWT tokens for enhanced security. Additionally, I created a checklist to ensure a seamless and reliable user experience on the platform, vital for mental health services."
                        ghLink="https://github.com/AdrianT18/Mental-Health-App"
                    />
                </Col>
            </Row>
        </Container>
        <ScrollToTop/>
    </Container>);
}

export default Projects;
