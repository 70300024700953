import React from "react";
import Card from "react-bootstrap/Card";
import {AiOutlineArrowRight} from "react-icons/ai";

function AboutCard() {
    return (
        <Card className="quote-card-view">
            <Card.Body>
                <blockquote className="blockquote mb-0" data-aos="zoom-in">
                    <p style={{textAlign: "justify"}}>
                        Hello everyone! I'm <span className="primary-header">Adrian Topolski</span>, from
                        London. Currently, I am pursuing my passion in Computer Science at Brunel
                        University London, with my sights set on graduating in June 2024.
                        <br/>
                        <br/>
                        In addition to coding, here are some other things I enjoy doing!
                    </p>
                    <ul>
                        <li className="about-activity">
                            <AiOutlineArrowRight/> Reading Books
                        </li>
                        <li className="about-activity">
                            <AiOutlineArrowRight/> Watching anime
                        </li>
                        <li className="about-activity">
                            <AiOutlineArrowRight/> Going to the gym
                        </li>
                        <li className="about-activity">
                            <AiOutlineArrowRight/> Traveling
                        </li>
                    </ul>
                </blockquote>
            </Card.Body>
        </Card>
    );
}

export default AboutCard;
