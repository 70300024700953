import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import myImg from "../../Assets/Avatar.jpeg";
import Tilt from "react-parallax-tilt";
import {
    AiFillGithub,
    AiOutlineMail,
} from "react-icons/ai";
import {FaLinkedinIn} from "react-icons/fa";

function Home2() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{fontSize: "2.6em"}} data-aos="fade-right">
                            <span className="primary-header"> ABOUT </span> ME
                        </h1>
                        <p className="home-about-body" data-aos="fade-up">
                            <p>
                                <i className="primary-header">Computer Science student</i> at Brunel University London,
                                passionate about <i className="primary-header">Web Technologies </i>, <i
                                className="primary-header">Artificial Intelligence </i> and <i
                                className="primary-header">problem-solving</i>.
                                Proficient in Java, JavaScript, Python, SQL, and various frameworks.
                            </p>
                            <p>
                                Earning a BSc in Computer Science with <i className="primary-header"> First-Class </i> Honours expected.
                                Experience in technical roles and customer service, with a knack for project leadership.
                            </p>
                            <p>
                                Led diverse projects including a <i className="primary-header"> mental health web app </i> and a <i className="primary-header"> multi-sensor fusion </i> project
                                for
                                Formula Student AI, showcasing my innovation and teamwork skills.
                            </p>
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img
                                data-aos="fade-left"
                                src={myImg}
                                className="img-fluid circular-profile"
                                alt="avatar"
                            />
                        </Tilt>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1 data-aos="fade-right">
                            <span className="primary-header">CONNECT </span> WITH ME
                        </h1>
                        <p data-aos="fade-left">Feel free to connect with me</p>
                        <ul className="home-about-social-links" data-aos="fade-up">
                            <li className="social-icons">
                                <a
                                    href="https://github.com/AdrianT18"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                    aria-label="github"
                                >
                                    <AiFillGithub/>
                                </a>
                            </li>
                            <li className="social-icons">
                                <a
                                    href="mailto:adriantopolski65@gmail.com"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                    aria-label="email"
                                >
                                    <AiOutlineMail/>
                                </a>
                            </li>
                            <li className="social-icons">
                                <a
                                    href="https://www.linkedin.com/in/adrian-topolski/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                    aria-label="linkedin"
                                >
                                    <FaLinkedinIn/>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home2;
